'use client'
import React, { useLayoutEffect, useState } from "react"

export default function useIntersectionObserver(
    containerRef: React.RefObject<any>,
    options: object = {},
    callback: Function | null = null,
) {
    const [isIntersecting, setIsIntersecting] = useState(true)

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting)
            callback?.(entry.isIntersecting)
        }, options)

        const currentContainerRef = containerRef.current

        if (currentContainerRef) {
            observer.observe(currentContainerRef)
        }

        return () => {
            observer.unobserve(currentContainerRef)
        }
    })

    return isIntersecting
}
