import Link from "next/link";
import Image from "next/image";
import logo from '@/app/assets/logo-reassurez-moi.png'
import React, {createRef, useCallback, useEffect, useState} from "react";
import useIntersectionObserver from "@/app/hooks/useIntersectionObserver";
import AppIcon from "@/app/components/atomic/AppIcon";
import StarsRating from "@/app/components/atomic/StarsRating";
import {useShallow} from "zustand/react/shallow";
import AppButton from "@/app/components/atomic/molecules/AppButton";

const MODULES = [
    'Rating',
    'CatchPhrase',
    'Logo',
    'CallBlock',
    'SaleBlock',
    'Nav',
    'StepBackButton',
    'StepForwardButton',
] as const;

type Module = (typeof MODULES)[number] | React.FC
interface WithSections {
    start?: Module[],
    center?: Module[],
    end?: Module[],
    [key: string]: Module[] | undefined;
}

export interface IBaseHeader extends WithSections {
    [key: string]: any;
    children?: any,
    shouldDetach?: boolean,
    classes?: string,
    blockProps?: Partial<Record<(typeof MODULES)[number], any>>,
    responsive?: WithSections,
}

const SECTIONS_CLASSES: {[key: string]: string} = {
    start: "justify-self-start text-left",
    center: "justify-self-center text-center justify-center",
    end: "justify-self-end text-end justify-end",
}

function BaseHeader(props: IBaseHeader) {
    const bodyTopDiv = createRef<HTMLDivElement>()
    const isIntersecting = useIntersectionObserver(
        bodyTopDiv,
        {},
        (isIntersecting: boolean) => {
        },
    )
    const isHeaderIntersecting = props.shouldDetach ? isIntersecting : false

    const defaultIntersectingClass = props.shouldDetach
        ? 'lg:fixed min-w-full'
        : ''
    const headerIntersectingClass = !props.shouldDetach || isHeaderIntersecting
        ? ''
        : 'lg:min-w-[60%] lg:backdrop-blur-md lg:mt-2.5 lg:rounded-lg lg:bg-white/70'

    return <div className={`flex justify-center items-center min-h-20 h-[80px] ${props.classes}`}>
         <div
            ref={bodyTopDiv}
            className="h-0"
        />
        <header
            className={`${headerIntersectingClass} ${defaultIntersectingClass} flex content-center items-center justify-between px-3 min-h-20 h-[80px] lg:top-0 lg:right-0 lg:left-0
        !container mx-auto relative top-0 z-50 lg:transition-[min-width,opacity,margin,background-color,border-radius] lg:duration-500 bg-white/100`}
        >
            {
                (['start', 'center', 'end']).map((sectionName) => <RenderedSection
                    key={sectionName}
                    section={props[sectionName]}
                    blockProps={props.blockProps}
                    responsiveSection={props.responsive?.[sectionName]}
                    sectionName={sectionName}
                />)
            }
        </header>
    </div>
}

function RenderedSection({section, blockProps, sectionName, responsiveSection = null}: any) {
    const partClasses = "flex-row gap-4 items-center"
    return <>
        <div
            key={sectionName}
            className={`${partClasses} items-center flex-1 ${SECTIONS_CLASSES[sectionName]} ${responsiveSection ? 'hidden' : 'flex'} rm-lg:flex`}
        >
            {getBlocksForSection({section, blockProps})}
        </div>
        {responsiveSection && <div
            key={`${sectionName}-responsive`}
            className={`${partClasses} items-center flex-1 ${SECTIONS_CLASSES[sectionName]} flex rm-lg:hidden`}
        >
            {getBlocksForSection({section: responsiveSection, blockProps})}
        </div>}
    </>
}

const getBlocksForSection = ({section, blockProps}: any) => {
    return section?.map((component: Module, index: number) => (
        <div
            key={`module-${index}`}
            className={'flex justify-between items-center min-w-fit'}
        >
            <RenderedBlock
                component={component}
                key={index}
                index={index}
                useSmallVariant={section.length > 2}
                blockProps={blockProps}
            />
        </div>
    ))
}

function RenderedBlock(props: any) {
    const getFinalComponent = useCallback(() => {
        const { component }: { component: Module } = props
        // if (React.isValidElement(component)) return component
        const Component = typeof component === "string"
            ? (BaseHeader as any)[component]
            : component
        return <Component
            key={`block-${props.index}`}
            useSmallVariant={props.useSmallVariant}
            blockProps={props.blockProps}
        />
    }, [props.component])
    return getFinalComponent()
}

BaseHeader.Logo = React.memo(function Logo() {
    return  <Link href='/'>
            <Image
                src={logo}
                alt='Comparateur Assurance'
                width={170}
                className='py-1 md:max-w-none'
            />
        </Link>
})

function MobileNav() {
    const [isNavOpen, setIsNavOpen] = useState(false)

    return (
        <nav className='flex justify-end lg:hidden'>
            <i
                onClick={() => setIsNavOpen(!isNavOpen)}
                className="cursor-pointer fas fa-light fa-bars px-5 py-2 text-3xl text-blue-700"
            />
            {isNavOpen && (
                <>
                    <div
                        className="fixed top-0 right-0 z-50 h-screen bg-white lg:hidden p-8 flex flex-col justify-start gap-4 w-[65%]"
                    >
                        {NAV_LINKS.map(navLink => <DropDownMenu key={navLink.label} link={navLink} />)}
                        <div className="w-full h-[1px] bg-blue-700"></div>
                        {RM_LINKS.map(navLink => <DropDownMenu key={navLink.label} useSecondAccent link={navLink} />)}
                    </div>
                    <div className="overlay absolute">
                        <div
                            className="fixed top-0 right-0 left-0 z-30 h-screen w-screen bg-black/50"
                            onClick={() => setIsNavOpen(false)}
                        >
                            <div className="relative left-8 top-[48%] z-40 rounded-full bg-white h-12 w-12 flex items-center justify-center">
                                <AppIcon
                                    onClick={() => setIsNavOpen(false)}
                                    iconClassName="fa-times"
                                    size="2xl"
                                    className="text-blue-700 cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </nav>
    )
}

BaseHeader.Nav = function Nav({}) {
    return <>
        <MobileNav />
        <nav
            className='hidden md:justify-end lg:!flex lg:items-stretch text-blue-700 text-sm'
        >
            {NAV_LINKS.map(navLink => <DropDownMenu key={navLink.label} link={navLink}/>)}
            <div className="border-l border-blue-700">
                {RM_LINKS.map(navLink => <DropDownMenu key={navLink.label} link={navLink}/>)}
            </div>
        </nav>
    </>
}

BaseHeader.CatchPhrase = function CatchPhrase() {
    return <span className={"text-blue-800 hidden md:!flex font-light"}>
            Le n°1 des courtiers en ligne
    </span>
}

BaseHeader.SaleBlock = React.memo(function SaleBlock(props: any) {
    // TODO Clean ProductStore type
    const { currentSale } = props.blockProps.SaleBlock.productStore(useShallow(
        (state: any) => ({ currentSale: state.currentSale })
    ));
    const phone = "01 88 46 94 67"

    return <div className={'flex items-center content-center gap-3'}>
        <div className="hidden md:!flex flex-col items-end h-full justify-between text-slate-900 font-thin">
            <div className="text-[11px] font-bold leading-3">{currentSale?.name}.</div>
            <div className="text-[11px]">Expert mutuelle santé</div>
            <div className="text-[11px] text-green-800 font-bold">
                {phone}
            </div>
        </div>
        <div className="relative size-14 hidden md:!block">
            <Image
                src={`/assets/avatars/sales/SQUARE_${currentSale?.image}.png`}
                alt={currentSale?.name}
                fill
                className={"min-h-full rounded shadow-soft object-contain z-10"}
            />
            <div className={"rounded-full absolute right-0 w-2 outline outline-white outline-2 h-2 bg-green-900 animate-pulse z-20"}></div>
        </div>
        <BaseHeader.CallBlock useSmallVariant phone={phone} />
    </div>
})

BaseHeader.CallBlock = function CallBlock({ useSmallVariant = false, phone = "01 82 83 36 33" }: any) {
    return <div className={"!flex gap-2"}>
        {!useSmallVariant && <div className={"hidden md:!flex flex-col my-auto"}>
            <a href={`tel:${phone}`}
               className={"flex place-self-end whitespace-nowrap text-lg font-bold text-green-900 no-underline md:text-sm"}>
                {phone}
            </a>
            <span className={"font-light"}>
                du lundi au vendredi 9h/18h
            </span>
        </div>}
        <div className={"!block"}>
            <a href={`tel:${phone}`}>
                <div className={`transition-all text-container text-with-icon rounded
                w-[30px] h-[30px] md:w-[50px] md:h-[50px] 
                flex flex-col justify-center items-center gap-1 font-bold bg-green-100 !p-3 text-green-800 hover:bg-green-200 text-xs`}>
                    <AppIcon iconClassName={"fas fa-phone"} size={"xs"} />
                    <p className={'hidden md:block mb-0 text-[8px]'}>Gratuit</p>
                </div>
            </a>
        </div>
    </div>
}

BaseHeader.Rating = React.memo(function Rating({ useSmallVariant = false}: any) {
    const [reviewsCount, setReviewsCount] = useState(209)
    const [stars, setStars] = useState(4.6)

    useEffect(() => {
        // ADD reviewRaitng window shims
        if (window.reviewRating) {
            setReviewsCount(parseFloat(parseFloat(window.reviewRating).toFixed(1)))
        }
    }, []);


    return <div className={"flex flex-row gap-4 items-center"}>
        {!useSmallVariant && <StarsRating
            notationOnTheLeft
            displayRatingNotation
            displayMaxNotation={false}
            notation={stars}
            starsFontSize={"text-lg"}
            noteColorClass={"text-yellow-700 max-rm-md:hidden"}
        />}
        <div
            className="hidden md:!flex items-center justify-center border border-blue-200 rounded-[100px] p-2
                    gap-2"
        >
            <img
                src="https://reassurez-moi.fr/build/images/google.ccf77075.png"
                className="w-[20px]"
            />
            <a
                href="https://www.google.com/maps/place/R%C3%A9assurez-moi/@48.8783926,2.3355747,17z/data=!4m8!3m7!1s0x47e66e3bed5e5a99:0x7427768f37949ef1!8m2!3d48.8783926!4d2.3355747!9m1!1b1!16s%2Fg%2F11clsw_7rm?entry=ttu"
                target="_blank"
                className=" hidden md:!block text-blue-600 text-xs underline flex-1"
            >
                {reviewsCount || "Voir les "} avis
            </a>
        </div>
    </div>;
})

const StepButton = React.memo(function StepButton({ forward = false }: { forward?: boolean }) {
    return <AppButton
        theme={"step"}
        size={'small'}
        disabled={true}
    >
        <AppIcon iconClassName={forward ? 'fa-arrow-right'  :'fa-arrow-left'} size={'xs'} />
    </AppButton>
})

BaseHeader.StepBackButton = React.memo(function ButtonStep() {
    return <StepButton />
})

BaseHeader.StepForwardButton = React.memo(function ButtonStep() {
    return <StepButton forward />
})

const DEFAULT_COMPARATOR_LINK = {label: 'Comparer'}


const NAV_LINKS: NavLink[] = [
    {
        label: 'Assurance emprunteur',
        children: [
            {
                label: 'Comparateur assurance prêt immobilier',
                url: '/simulation-assurance-pret-immobilier'
            },
            {
                label: 'Changer assurance de prêt immobilier',
                url: '/guide/assurance-pret-immobilier/changer'
            },
            {
                label: 'Loi Lemoine',
                url: '/guide/assurance-pret-immobilier/loi-lemoine'
            },
            {
                label: 'Taux assurance emprunteur',
                url: '/guide/assurance-pret-immobilier/taux'
            },
            {
                label: 'Calcul assurance prêt immobilier',
                url: '/guide/assurance-pret-immobilier/calcul'
            },
            {
                label: 'Délégation d\'assurance',
                url: '/guide/assurance-pret-immobilier/calcul'
            },
        ],
    },
    {
        label: 'Prêt Immobilier',
        children: [
            {
                label: 'Taux prêt immobilier',
                url: '/taux-pret-immobilier'
            },
            {
                label: 'Simulation prêt immobilier',
                url: '/guide/pret-immobilier'
            },
            {
                label: 'Calculette prêt immobilier',
                url: '/guide/pret-immobilier/calcul-mensualites'
            },
            {
                label: 'Calcul capacité d\'emprunt',
                url: '/guide/pret-immobilier/capacite-emprunt'
            },
        ],
    },
    {
        label: 'Mutuelle santé',
        children: [
            {
                label: 'Comparateur mutuelle santé',
                url: '/comparateur-mutuelle-sante'
            },
            {
                label: 'Changer de mutuelle',
                url: '/guide/mutuelle-sante/changer'
            },
            {
                label: 'Mutuelle expatrié',
                url: '/guide/mutuelle-sante/expatries'
            },
            {
                label: 'Mutuelle senior',
                url: '/guide/mutuelle-sante/senior'
            },
            {
                label: 'Mutuelle étudiante',
                url: '/guide/mutuelle-sante/mutuelle-etudiante'
            },
        ],
    },

    {
        label: 'Prévoyance',
        children: [
            {
                label: 'Assurance décès',
                url: '/guide/assurance-deces'
            },
            {
                label: 'Assurance décès tarif',
                url: '/guide/assurance-deces/prix'
            },
            {
                label: 'Assurance obsèques',
                url: '/guide/assurance-obseques'
            },
            {
                label: 'Assurance dépendance',
                url: 'guide/assurance-dependance'
            },
        ],
    },
    {
        label: 'Assurance Voyage',
        children: [
            {
                label: 'Comparateur assurance voyage',
                url: '/guide/assurance-voyage'
            },
            {
                label: 'Assurance annulation voyage',
                url: '/guide/assurance-voyage/annulation-voyage'
            },
        ],
    },


    {
        label: 'Nos assurances',
        children: [
            {
                label: 'Rachat de crédit',
                url: '/guide/rachat-credit'
            },
            {
                label: 'Assurance vélo',
                url: '/guide/assurance-loisirs/velo'
            },
            {
                label: 'Assurance trottinette électrique',
                url: '/guide/assurance-loisirs/trottinette'
            },
            {
                label: 'Assurance auto',
                url: '/guide/assurance-auto'
            },
            {
                label: 'Assurance VTC',
                url: '/guide/assurance-auto/vtc'
            },
            {
                label: 'Assurance animaux',
                url: '/guide/assurance-chien-chat'
            },
            {
                label: 'Assurance habitation',
                url: '/guide/assurance-habitation/meilleures'
            },
            {
                label: 'Assurance Responsabilité Civile Professionnelle',
                url: '/guide/pro/responsabilite-civile'
            },
            {
                label: '> Toutes nos assurances',
                url: '/guide/'
            },
        ],
    },
]
const RM_LINKS: NavLink[] = [
    {
        label: 'Actualités',
        url: '/guide/actualites',
    },
]

type NavLink = {
    label: string,
    url?: string,
    mobileIcon?: string,
    children?: NavLink[],
}

function DropDownMenu({link, useSecondAccent = false}: { link: any, useSecondAccent?: boolean }) {
    const menuProps = {...link.url && {href: link.url}}
    const subMenuClasses = 'absolute right-0 top-full z-10 top-[120%] group-hover:opacity-100 opacity-0 group-hover:pointer-events-auto group-hover:h-min'

    return (
        <div
            className={`transition-all cursor-pointer group relative flex md:flex-1 flex-col flex-nowrap items-center gap-1.5 px-2 py-2 rounded
            hover:bg-gradient-to-r hover:from-blue-200/50 hover:to-blue-200 hover:outline outline-blue-200 justify-between outline-1 ${useSecondAccent ? 'text-blue-600' : ''}`}
        >
            <a
                className='flex flex-nowrap justify-between w-full items-center gap-2.5 whitespace-pre z-20'
                {...menuProps}
            >
                {link.label} {link.children && <AppIcon size="sm" iconClassName="fa-chevron-down" />}
            </a>
            {link.children && (
                <div
                    className={`rounded-[8px] bg-white whitespace-nowrap px-[5px]
                    group-hover:py-[5px] shadow-[0px_2px_8px_#00000026] h-0 overflow-hidden transition-[opacity] duration-500 ease-in-out pointer-events-none ${subMenuClasses} z-30`}
                >
                    {link.children?.map((sublink: NavLink) => (
                        <a
                            key={sublink.label}
                            href={sublink.url}
                            className='text-sm block text-blue-700 hover:bg-blue-100 hover:no-underline p-[5px] rounded'
                        >
                            {sublink.label}
                        </a>
                    ))}
                </div>
            )}
        </div>
    )
}

const MemoedHeader = React.memo(BaseHeader)
export default MemoedHeader
